import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import CookieConsent from './CookieConsent'

const Footer = () => {
	const setDataCopyright = new Date().getFullYear();
	
	return (
		<div className="footer">
			<Container>
				<Row className="app-footer-detail-links-row-custom">
					<Col xs={12} md={3} lg={3}>
						<div className="app-footer-logo">
							<img className="app-logo-footer" src="/app-logo-footer.svg" alt="app footer logo" />
							<img className="app-logo-footer-dark" src="/app-logo-footer-dark.svg" alt="app footer logo" />
							<h6 className="app-data-en">Appvity consists of a team of professionals 
								that aspires to build productivity software 
								that enables information professionals to 
								work effectively. We build products that focus 
								on Azure and Office 365 cloud platform.</h6>
							<h6 className="app-data-vi">Appvity mong muốn xây dựng các phần mềm thực tiễn giúp quản lý và tăng hiệu quả trong hoạt động doanh nghiệp. Chúng tôi tập trung xây dựng các sản phẩm trên nền tảng đám mây Azure và Office 365.</h6>
						</div>
					</Col>
					<Col xs={12} md={1} lg={1}></Col>
					<Col xs={12} md={2} lg={2}>
						<ul className="app-list-general">
							<h5><span className="app-data-en">Company</span><span className="app-data-vi">Doanh Nghiệp</span></h5>
							<li>
								<h6><Link to="/about-us"><span className="app-data-en">About Us</span><span className="app-data-vi">Về Chúng Tôi</span></Link></h6>
								<h6><Link to="/support/faqs"><span className="app-data-en">FAQs</span><span className="app-data-vi">Câu Hỏi Thường Gặp</span></Link></h6>
							</li>
						</ul>
					</Col>
					<Col xs={12} md={2} lg={2}>
						<ul className="app-list-general">
							<h5><span className="app-data-en">Works</span><span className="app-data-vi">Cung Cấp</span></h5>
							<li>
								<h6><Link to="/products"><span className="app-data-en">Products</span><span className="app-data-vi">Sản Phẩm</span></Link></h6>
								<h6><Link to="/solutions"><span className="app-data-en">Solutions</span><span className="app-data-vi">Giải Pháp</span></Link></h6>
								<h6><Link to="/services"><span className="app-data-en">Services</span><span className="app-data-vi">Dịch Vụ</span></Link></h6>
							</li>
						</ul>
					</Col>
					<Col xs={12} md={2} lg={2}>
						<ul className="app-list-general">
							<h5><span className="app-data-en">Support</span><span className="app-data-vi">Hỗ Trợ</span></h5>
							<li>
								<h6><Link to="/support"><span className="app-data-en">Support Center</span><span className="app-data-vi">Trung Tâm Hỗ Trợ</span></Link></h6>
								<h6><a href="/OnlineDoc"><span className="app-data-en">Online Docs</span><span className="app-data-vi">Tài Liệu Trực Tuyến</span></a></h6>
								{/* <h6><Link to="/support/blogs"><span className="app-data-en">Blogs</span><span className="app-data-vi">Blogs</span></Link></h6> */}
							</li>
						</ul>
					</Col>
					<Col xs={12} md={2} lg={2}>
						<div className="app-footer-partner-certificate">
							{/* <img className="app-icon-partner" src="/app-icon-partner.svg" alt="app icon partner" />
							<img className="app-icon-partner-dark" src="/app-icon-partner-dark.svg" alt="app icon partner" /> */}
							<img src="/app-icon-authorize.svg" alt="app icon authorize" />
						</div>
					</Col>
				</Row>
				<Row className="app-footer-copyright-anotherlinks-row-custom">
					<Col xs={12} md={12} lg={4} className="app-footer-copyright-col-custom">
						<div className="app-footer-copyright">
							<h6 className="app-data-en">© {setDataCopyright} Appvity. All rights reserved.</h6>
							<h6 className="app-data-vi">© {setDataCopyright} Bản quyền bởi Appvity Việt Nam.</h6>
						</div>
					</Col>
					<Col xs={12} md={12} lg={8} className="app-footer-anotherlinks-col-custom">
						<ul className="app-footer-another-links">
							<li><Link to="/privacy-policy"><span className="app-data-en">Privacy Policy</span><span className="app-data-vi">Chính Sách Bảo Mật</span></Link></li>
							<li><Link to="/terms-of-service"><span className="app-data-en">Terms of Service</span><span className="app-data-vi">Điều Khoản Dịch Vụ</span></Link></li>
							<li><Link to="/accessibility"><span className="app-data-en">Accessibility</span><span className="app-data-vi">Trợ Năng</span></Link></li>
							<li><Link to="/sitemap"><span className="app-data-en">Site Map</span><span className="app-data-vi">Sơ Đồ Trang</span></Link></li>
						</ul>
						<div className="app-footer-social">
							<Link to="/contact-us">
								<img 
									className="app-icon-contact-us"
									src="/app-icon-contact-us.svg" 
									alt="app icon contact us" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us-hover.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us-hover.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us').setAttribute('src', '/app-icon-contact-us.svg')} 
								/>
								<img 
									className="app-icon-contact-us-dark"
									src="/app-icon-contact-us-dark.svg" 
									alt="app icon contact us dark" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-dark.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-hover-dark.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-hover-dark.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-dark.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-contact-us-dark').setAttribute('src', '/app-icon-contact-us-dark.svg')} 
								/>
							</Link>
							<a href="https://www.facebook.com/appvity">
								<img 
									className="app-icon-facebook"
									src="/app-icon-facebook.svg" 
									alt="app icon facebook" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook-hover.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook-hover.svg')}
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook').setAttribute('src', '/app-icon-facebook.svg')} 
								/>
								<img 
									className="app-icon-facebook-dark"
									src="/app-icon-facebook-dark.svg" 
									alt="app icon facebook dark" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-dark.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-hover-dark.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-hover-dark.svg')}
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-dark.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-facebook-dark').setAttribute('src', '/app-icon-facebook-dark.svg')} 
								/>
							</a>
							<a href="https://www.linkedin.com/company/appvity">
								<img 
									className="app-icon-linkedin"
									src="/app-icon-linkedin.svg" 
									alt="app icon linkedin" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin-hover.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin-hover.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin').setAttribute('src', '/app-icon-linkedin.svg')} 
								/>
								<img 
									className="app-icon-linkedin-dark"
									src="/app-icon-linkedin-dark.svg" 
									alt="app icon linkedin dark" 
									onClick={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-dark.svg')} 
									onMouseOver={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-hover-dark.svg')} 
									onFocus={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-hover-dark.svg')} 
									onMouseOut={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-dark.svg')} 
									onBlur={() => 
										document.querySelector('.footer .app-footer-copyright-anotherlinks-row-custom .app-footer-anotherlinks-col-custom .app-footer-social img.app-icon-linkedin-dark').setAttribute('src', '/app-icon-linkedin-dark.svg')} 
								/>
							</a>
						</div>
					</Col>
				</Row>
			</Container>
			<CookieConsent/>
		</div>
	)
}
export default Footer